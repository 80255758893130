import React, { useCallback, useEffect, useRef, useState } from "react";
import cn from "classnames";
import styles from "./Input.module.scss";

export const Input = ({
  placeholder,
  value,
  setValue,
  mask,
  ref,
  autocomplete = null,
  maskChar = null,
  isCodeField,
  error,
  type,
  textAlign = "center",
  isEvent,
  setIsEvent,
  alwaysShowMask = false,
  ...props
}) => {
  const [errorText, setErrorText] = useState(false);
  const inputRef = useRef(null);
  const [initialOtpRequested, setInitialOtpRequested] = useState(false);

  // Function to handle mask application
  const applyMask = useCallback(
    (inputValue) => {
      const matrix = `${mask}`;
      let i = 0;
      let def = matrix.replace(/\D/g, "");
      let val = inputValue.replace(/\D/g, "");

      if (def.length >= val.length) {
        val = def;
      }

      const newValue = matrix.replace(/./g, (a) => {
        return /[_\d]/.test(a) && i < val.length
          ? val.charAt(i++)
          : i >= val.length
          ? ""
          : a;
      });

      return newValue;
    },
    [mask]
  );

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  useEffect(() => {
    if (error) {
      setErrorText(true);
      const interval = setTimeout(() => {
        setErrorText(false);
      }, 1000);

      setValue("");

      return () => {
        clearTimeout(interval);
      };
    }
  }, [error, setValue]);

  useEffect(() => {
    if (
      ((isCodeField && !initialOtpRequested) || isEvent) &&
      "OTPCredential" in window
    ) {
      setInitialOtpRequested(true);
      const ac = new AbortController();

      navigator.credentials
        .get({
          otp: { transport: ["sms"] },
          signal: ac.signal,
        })
        .then((otp) => {
          if (otp && otp.code) {
            const maskedValue = applyMask(otp.code);
            setValue(maskedValue);
            if (inputRef.current) {
              inputRef.current.value = maskedValue;
            }
          }
          setIsEvent(false);
          ac.abort();
        })
        .catch((err) => {
          ac.abort();
          console.error(err);
          setIsEvent(false);
        });
    }
  }, [
    setValue,
    isEvent,
    applyMask,
    isCodeField,
    setIsEvent,
    initialOtpRequested,
  ]);

  const handleChange = (event) => {
    const newValue = applyMask(event.target.value);
    setValue(newValue);
  };

  return (
    <input
      type="text"
      value={value}
      ref={inputRef}
      onFocus={handleChange}
      onChange={handleChange}
      placeholder={placeholder}
      className={cn(styles.input, {
        [styles.error]: errorText,
        [styles.placeholderColor]: placeholder,
      })}
      inputMode="numeric"
      pattern="\\d*"
      autoComplete={autocomplete}
      style={{ textAlign }}
      {...props}
    />
  );
};
