import styles from "./Payments.module.scss";
import { ButtonArrow } from "../../../assets/icons/ButtonArrow";
import { Button } from "../../../coomponents/Button";
import { useNavigate } from "react-router-dom";
import { Title } from "../../../coomponents/Title";
import { useEffect, useState } from "react";
import { ContentList } from "../../../coomponents/ContentList";
import cn from "classnames";
import { useGetLeadsQuery } from "../../../redux/projectApi";
import { Preloader } from "../../../coomponents/Preloader";
import { Error } from "../../../coomponents/Error/ui/Error";

export const Payments = () => {
  const { data, isLoading, isError } = useGetLeadsQuery(false);
  const {
    data: paymentsData,
    isLoading: paymentStatus,
    isError: paymentError,
  } = useGetLeadsQuery(true);
  const [activeTab, setActiveTab] = useState(1);
  const [payments, setPayments] = useState();
  const navigate = useNavigate();

  const allPayments = () => {
    setPayments(paymentsData?.payments);
  };

  const notPayments = () => {
    setPayments(data.payments);
  };

  const okPayments = () => {
    const newPayments = [...paymentsData.payments].filter(
      (payment) => payment.is_executed === true
    );
    setPayments(newPayments);
  };

  useEffect(() => {
    if (paymentsData && paymentsData?.payments) {
      setPayments(paymentsData?.payments);
    }
  }, [paymentsData]);

  return (
    <div className={styles.paymentsWrapper}>
      <Button
        variant="transparent"
        className={styles.paymentsBtn}
        onClick={() => navigate(-1)}
      >
        <ButtonArrow />
        Назад
      </Button>
      <Title>Счета</Title>
      <div className={styles.paymentsFilters}>
        <Button
          variant={activeTab === 1 ? "yellow" : "gray"}
          onClick={() => {
            setActiveTab(1);
            allPayments();
          }}
          className={cn(styles.allBtn, {
            [styles.active]: activeTab === 1,
          })}
        >
          Все
        </Button>
        <Button
          variant={activeTab === 2 ? "yellow" : "gray"}
          onClick={() => {
            setActiveTab(2);
            notPayments();
          }}
          className={activeTab === 2 ? styles.active : ""}
        >
          Оплатить
          {data && (
            <span className={styles.infoSpan}>{data?.payments?.length}</span>
          )}
        </Button>
        <Button
          variant={activeTab === 3 ? "yellow" : "gray"}
          onClick={() => {
            setActiveTab(3);
            okPayments();
          }}
          className={activeTab === 3 ? styles.active : ""}
        >
          Оплаченные
        </Button>
      </div>
      {isLoading || paymentStatus ? (
        <Preloader className={styles.preloader} />
      ) : isError || paymentError ? (
        <Error />
      ) : (
        <ContentList paymentsData={payments} />
      )}
    </div>
  );
};
