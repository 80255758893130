import styles from "./Auth.module.scss";
import { Title } from "../../../coomponents/Title";
import { Input } from "../../../coomponents/Input";
import { Button } from "../../../coomponents/Button";
import { useNavigate } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { useEffect, useState } from "react";
import { useUserAuthMutation } from "../../../redux/projectApi";
import { transformNumber } from "../../../helpers/transformNumber";
import { Error } from "../../../coomponents/Error/ui/Error";
import { CustomError } from "../../../coomponents/CustomError";

export const Auth = () => {
  const [value, setValue] = useState("");
  const navigate = useNavigate();
  const [userAuth, { isSuccess, error }] = useUserAuthMutation();

  const handleUserAuth = async () => {
    if (value) {
      let phone_number = transformNumber(value)
      await userAuth({ phone: phone_number });
      localStorage.setItem("phone", phone_number);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      navigate("/verify");
    }
  }, [isSuccess, navigate]);

  return (
    <div className={styles.authWrapper}>
      <Title>
        Войдите <br /> по номеру телефона
      </Title>
      <div className={styles.authBlock}>
        <Input
          mask={"+7 ___ ___-__-__"}
          value={value}
          setValue={setValue}
          textAlign="start"
        />
        <Button
          variant={"yellow"}
          onClick={handleUserAuth}
          // disabled={isError ? true : false}
        >
          Войти
        </Button>
      </div>

      {error && error.status === 401 ? (
        <CustomError
          text={"Пользователь не найден. Вам нужно зарегистрироваться"}
        />
      ) : (
        error && error.status !== 401 && <Error className={styles.authError} />
      )}
    </div>
  );
};
