export const formattedDateFunc = (dateArg, isShortFormat) => {
  const date = new Date(dateArg);

  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  };

  const formattedDate = date.toLocaleDateString("ru-RU", options);
  

  if (isShortFormat) {
  } else {
    const finalFormattedDate = `${
      formattedDate.split(" г.")[0]
    }`;

    return finalFormattedDate;
  }
};