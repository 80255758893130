import styles from "./Error.module.scss";
import cn from "classnames";

export const Error = ({ className }) => {
  return (
    <p className={cn(styles.errorInfo, className)}>
      <p className={styles.errorDescr}>Что-то пошло не так!</p> Свяжитесь с
      вашим менеджером по телефону: <br />
      <span>
        <a className={styles.errorLink} href="tel:88007707473">
          8-800-770-74-73
        </a>
      </span>
    </p>
  );
};
