import {useEffect, useState} from "react";
import {Navigate, useLocation} from "react-router-dom";

export const CheckAuth = ({children}) => {
  const accessToken = localStorage.getItem("token");
  const refresh = localStorage.getItem("refresh");
  const location = useLocation();
  const [isValid, setValid] = useState(true);
  const baseAppUrl = process.env.REACT_APP_API_BASE_URL;

  const isTokenExpired = (token) => {
    if (!token) {
      return true;
    }
    const payloadBase64 = token.split(".")[1];
    const decodedPayload = JSON.parse(atob(payloadBase64));
    const exp = decodedPayload.exp;
    if (!exp) {
      return true;
    }
    const currentTime = Math.floor(Date.now() / 1000);
    return exp + 5 < currentTime;
  };

  const regenerateToken = async (refreshToken) => {
    try {
      const response = await fetch(`${baseAppUrl + "users/auth/refresh"}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${refreshToken}`,
        },
      });

      if (!response.ok) {
        setValid(false);
        return;
      }

      const data = await response.json();
      localStorage.setItem("token", data.access_token);
      setValid(true);
    } catch (error) {
      console.error("Ошибка при обновлении токена:", error);
      setValid(false);
    }
  };

  const checkingToken = async () => {
    if (isTokenExpired(accessToken)) {
      if (!isTokenExpired(refresh)) {
        await regenerateToken(refresh);
      } else {
        setValid(false);
      }
    }
  };

  useEffect(() => {
    try {
      checkingToken();
    } catch (error) {
      console.log(error);
    }
  }, []);

  if (!isValid) {
    return <Navigate to="/auth" state={{from: location}}/>;
  } else {
    return children;
  }
};
