import styles from "./Success.module.scss";
import { Button } from "../../../coomponents/Button";
import { ButtonArrow } from "../../../assets/icons/ButtonArrow";
import { useNavigate, useParams } from "react-router-dom";
import { SuccessIcon } from "../../../assets/icons/SuccessIcon";
import { Title } from "../../../coomponents/Title";
import { useLazyGetDocumentQuery } from "../../../redux/projectApi";
import { useEffect } from "react";
import { ContentBox } from "../../../coomponents/ContentBox";


export const Success = () => {
  const navigate = useNavigate();
  const { file, id } = useParams();
  const [getDocument, result] = useLazyGetDocumentQuery();
  

  useEffect(() => {
    getDocument(id);
  }, [file, id, getDocument]);

  return (
    <div className={styles.successWrapper}>
      <Button
        variant="transparent"
        className={styles.successBtn}
        onClick={() => navigate(-2)}
      >
        <ButtonArrow />
        Назад
      </Button>
      <div className={styles.titleBox}>
        <SuccessIcon />
        <Title>
          {file === "document" && "Документ"}
          <span> успешно подписан</span>
        </Title>
      </div>
      <ContentBox
        isDocument={true}
        text={result?.data?.name}
        labelVariant={result.isSuccess && "green"}
        labelText={result.isSuccess && "Подписан"}
        onClick={() => navigate(`/documents/${id}`)}
      />
    </div>
  );
};
