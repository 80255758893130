import styles from './Button.module.scss'
import cn from 'classnames'

export const Button = ({children, variant, onClick, className, ...props}) => {

  return(
    <button className={cn(styles.btn, className, {
      [styles.yellow]: variant === 'yellow',
      [styles.gray]: variant === 'gray',
      [styles.green]: variant === 'green',
      [styles.transparent]: variant === 'transparent',
      [styles.yellowBorder]: variant === 'yellow-border'
    })} onClick={onClick} {...props}>{children}</button>
  )
}