import { Routes, Route, Navigate } from "react-router-dom";
import { Auth } from "../../../pages/Auth";
import { Verify } from "../../../pages/Verify";
import { Main } from "../../../pages/Main";
import { Documents } from "../../../pages/Documents";
import { Document } from "../../../pages/Document";
import { Payments } from "../../../pages/Payments";

import { Error } from "../../../pages/Error";
import { Success } from "../../../pages/Success/ui/Success";

import { CheckAuth } from "../../CheckAuth/ui/CheckAuth";
import { AdsApproval, Agreement, Approval } from "../../../pages/Approval";
import { Register } from "../../../pages/Register";

const paths = [
  {
    id: 1,
    path: "/auth",
    element: <Auth />,
  },
  {
    id: 2,
    path: "/verify",
    element: <Verify />,
  },

  {
    id: 3,
    path: "/",
    element: <Main />,
    protected: true,
  },
  {
    id: 4,
    path: "/documents",
    element: <Documents />,
    protected: true,
  },
  {
    id: 5,
    path: "/documents/:id",
    element: <Document />,
    protected: true,
  },
  {
    id: 6,
    path: "/payments",
    element: <Payments />,
    protected: true,
  },

  {
    id: 7,
    path: "/check/:file/:id",
    element: <Verify />,
    protected: true,
  },

  {
    id: 8,
    path: "*",
    element: <Auth />,
  },

  {
    id: 9,
    path: "/error/:file/:id",
    element: <Error />,
    protected: true,
  },

  {
    id: 10,
    path: "/success/:file/:id",
    element: <Success />,
    protected: true,
  },

  {
    id: 11,
    path: "/error",
    element: <Error />,
  },

  {
    id: 12,
    path: "/approval",
    element: <Approval />,
  },

  {
    id: 13,
    path: "/register",
    element: <Register />,
  },

  {
    id: 14,
    path: "/register/*",
    element: <Navigate to={"/register"} />,
  },

  {
    id: 15,
    path: "/agreement",
    element: <Agreement />,
  },

  {
    id: 16,
    path: "/ads-approval",
    element: <AdsApproval />,
  },
];

export const AppRouter = () => {
  return (
    <Routes>
      {paths.map((path) =>
        path.protected ? (
          <Route
            key={path.id}
            path={path.path}
            element={<CheckAuth children={path.element} />}
          />
        ) : (
          <Route key={path.id} path={path.path} element={path.element} />
        )
      )}
    </Routes>
  );
};
