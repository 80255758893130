import styles from './ProgressBar.module.scss'
import cn from 'classnames'

export const ProgressBar = ({current, max}) => {
  const progressWidth = `${(current / max) * 100}%`

  return(
    <div className={styles.progressWrapper}>
      <div className={styles.progressValues}>
        <span className={styles.currentValue}>{current} ₽</span>
        <span className={styles.maxValue}>{max} ₽</span>
      </div>
      <div className={styles.progressInner}>
        <span className={cn(styles.progress, {
          [styles.yellowProgress]: progressWidth !== '100%',
          [styles.greenProgress]: progressWidth === '100%'
        })} style={{width: progressWidth, display: 'block'}}></span>
      </div>
    </div>
  )
}