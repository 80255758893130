import { Title } from "../../../coomponents/Title";
import styles from "./Register.module.scss";
import { Input } from "../../../coomponents/Input";
import { useState } from "react";
import { Button } from "../../../coomponents/Button";
import { Checkbox } from "../../../coomponents/Checkbox/ui/Checkbox";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useUserRegisterMutation } from "../../../redux/projectApi";
import { transformNumber } from "../../../helpers/transformNumber";
import { CustomError } from "../../../coomponents/CustomError";
import { useGetRetrieveQuery } from "../../../redux/projectApi";

export const Register = () => {
  const [value, setValue] = useState("");
  const currentChoice = JSON.parse(localStorage.getItem('checked'))
  const [checked, setChecked] = useState(false);
  const [adsChecked, setAdsChecked] = useState(false)
  const [checkError, setCheckError] = useState(false);
  const [userRegister, { isSuccess, error }] = useUserRegisterMutation();
  const navigate = useNavigate();
  const location = useLocation();
  const userRetrieve = location.pathname.split("auth/")[1];
  const { data } = useGetRetrieveQuery(userRetrieve);

  const handleRegister = async () => {
    if (value) {
      localStorage.setItem("phone", value);
      await userRegister({
        consent_given: true,
        phone: transformNumber(value),
        advertising_consent: adsChecked
      });
    }
  };

  const registerErrorHandle = () => {
    setCheckError(true);
  };

  useEffect(() => {
    if (checked) {
      setCheckError(false);
    }

    localStorage.setItem('checked', checked)
  }, [checked]);

  useEffect(() => {
    if (isSuccess) {
      navigate("/verify");
    }
  }, [isSuccess, navigate]);

  useEffect(() => {
    if (data && data?.phone) {
      setValue(data?.phone);
      localStorage.setItem("phone", data?.phone);
    }
  }, [data]);

  useEffect(() => {
    if(currentChoice){
      setChecked(currentChoice)
    }
  }, [currentChoice])

  return (
    <div className={styles.registerWrapper}>
      <Title>Регистрация</Title>
      <div className={styles.registerBlock}>
        <Input
          mask={"+7 ___ ___-__-__"}
          value={value}
          setValue={setValue}
          textAlign="start"
        />
        <div className={styles.approvalBox}>
          <Checkbox
            value={checked}
            setValue={setChecked}
            error={checkError}
            setError={setCheckError}
          />
          <span className={styles.approvalText}>
            Я полностью ознакомлен с{" "}
            <span onClick={() => navigate("/approval")}>правилами</span>{" "}
            обработки персональных данных и <span onClick={() => navigate("/agreement")}>пользовательским соглашением</span> и безоговорочно их принимаю
          </span>
        </div>
        <div className={styles.approvalBox}>
          <Checkbox 
            value={adsChecked}
            setValue={setAdsChecked}
          />
          <span className={styles.approvalText}>
            Даю <span onClick={() => navigate('/ads-approval')}>Согласие</span> на получение информационной и рекламной информации
          </span>
        </div>
        <Button
          variant={checked ? "yellow" : "gray"}
          onClick={!checked ? registerErrorHandle : handleRegister}
        >
          Зарегистрироваться
        </Button>
        {checkError && (
          <CustomError
            text="Вы должны согласиться с правилами обработки персональных данных,
          чтобы зарегистрироваться"
          />
        )}
        {error && error.status === 409 && (
          <CustomError text="Вы уже зарегистрированы. Нажмите Вход чтобы войти" />
        )}
      </div>
    </div>
  );
};
