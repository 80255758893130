import { useNavigate } from "react-router-dom";
import { ButtonArrow } from "../../../../assets/icons/ButtonArrow";
import { Button } from "../../../../coomponents/Button";
import styles from "./Agreement.module.scss";

export const Agreement = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.agreementWrapper}>
      <Button
        variant="transparent"
        className={styles.agreementBtn}
        onClick={() => navigate(-1)}
      >
        <ButtonArrow />
        Назад
      </Button>
      <div className={styles.updateDate}>
        <span>Дата последнего обновления: 07 октября 2024 года</span>
      </div>
      <ul>
        <li>
          Настоящее Пользовательское соглашение (далее – Соглашение») регулирует
          отношения общества с ограниченной ответственностью «МЮБ Списание», ИНН
          1685017276, адрес места нахождения: г. Казань, ул.Гаврилова 1, пом.14
          (далее - «Оператор») и пользователей (далее – «Пользователи») при
          использовании платформы «МЮБ» (далее – «Платформа»).
        </li>
        <li>
          Платформа представляет собой цифровую площадку комплексных услуг для
          самостоятельного прохождения процедуры банкротства физическими лицами
          в режиме онлайн.
        </li>
        <li>
          Факт использования Пользователем Платформы, любых его служб,
          функционала, регистрация/авторизация Пользователя на Платформе
          является полным и безоговорочным акцептом настоящего Соглашения,
          незнание, не ознакомление с которым не освобождает Пользователя от
          ответственности за его несоблюдение.
        </li>
        <li>
          Используя Платформу, Пользователь подтверждает, что является
          физическим лицом и что ему исполнилось 18 лет. Пользователь
          самостоятельно несёт всю ответственность за нарушение возрастного
          условия.
        </li>
      </ul>
      <div className={styles.agreementInfo}>
        <span>
          2.1.  Для получения полноценного доступа к сервисам Платформы
          Пользователь должен зарегистрировать личный кабинет (далее -
          «Аккаунт»).
        </span>
        <span>
          2.2. Пользователь несет ответственность за безопасность выбранных им
          средств доступа к Аккаунту, а также обеспечивает их
          конфиденциальность. Пароль Пользователя должен быть конфиденциальным и
          без передачи третьим лицам.
        </span>
      </div>
      <ul>
        <li>
          Пользователь имеет право:
          <ul className={styles.nestedList}>
            <li>
              на предоставление доступа ко всей функциональности Платформы;
            </li>
            <li>
              на заключение с Оператором договоров на оказание услуг и их
              дальнейшее исполнение.
            </li>
          </ul>
        </li>
        <li>
          Пользователь обязуется:
          <ul className={styles.nestedList}>
            <li>
              обеспечивать сохранность личных данных от доступа третьих лиц;
            </li>
            <li>не нарушать работоспособность Платформы;</li>
            <li>
              не совершать действия, направленные на введение Оператора в
              заблуждение;
            </li>
            <li>
              не передавать в пользование свой Аккаунт и/или логин и пароль от
              Аккаунта третьим лицам;
            </li>
            <li>не нарушать настоящее Соглашение.</li>
          </ul>
        </li>
        <li>
          Оператор имеет право:
          <ul className={styles.nestedList}>
            <li>
              по своему усмотрению и необходимости изменять условия настоящего
              Соглашения;
            </li>
            <li>создавать, изменять, удалять информацию на Платформе;</li>
            <li>
              удалить Аккаунт Пользователя, если он не соблюдает любое из
              положений настоящего Соглашения или если действия, которые
              осуществляются с помощью его Аккаунта, по мнению Оператора
              причиняют или могут причинить ущерб или ухудшить качество услуг
              или ущемить или нарушить права третьих лиц или нарушают какие-либо
              законы или правила.
            </li>
          </ul>
        </li>
        <li>
          Оператор обязуется:
          <ul className={styles.nestedList}>
            <li>
              поддерживать работоспособность Платформы, за исключением случаев,
              когда это невозможно по независящим от Оператора причинам;
            </li>
            <li>осуществлять защиту Аккаунта Пользователя;</li>
            <li>
              защищать информацию, распространение которой ограничено или
              запрещено законами путем вынесения предупреждения либо удалением
              Аккаунта Пользователя, нарушившего правила.
            </li>
          </ul>
        </li>
        <li>
          Получая доступ к Платформе и используя ее Пользователь дает согласие
          на участие в проводимых Оператором маркетинговых, статистических и
          иных исследованиях и экспериментах, в том числе по оценке
          эффективности технической работы Платформы. При проведении
          исследования или эксперимента Оператор не обязан уведомлять
          Пользователя о том, что его профиль участвует в исследовании или
          эксперименте, за исключением случаев, когда проведение такого
          исследования или эксперимента препятствует или существенно затрудняет
          возможность Пользователя использовать Платформу.
        </li>
        <li>
          В случае использования в Аккаунте своего изображения Пользователь
          соглашается с тем, что Оператор вправе проводить опубликование и
          дальнейшее использование изображения и имя Пользователя для
          использования на Платформе и в рекламных продуктах Оператора.
        </li>
        <li>
          Пользователь, осуществляя использование сервиса Платформы, дает свое
          согласие на получение рекламной информации, размещаемой на Платформе,
          а также посредством сетей электросвязи.
        </li>
        <li>
          Пользователь несет ответственность за сохранность доступа к Аккаунту и
          за убытки, которые могут возникнуть по причине их несанкционированного
          использования. Оператор не несет ответственности в случае
          несанкционированного использования третьими лицами Аккаунта
          Пользователя. Все действия, совершенные с использованием Аккаунта
          Пользователя, рассматриваются как действия Пользователя.
        </li>
        <li>
          Пользователь обязан немедленно уведомить Оператора о любом случае
          несанкционированного доступа к Платформе с использованием Аккаунта
          Пользователя и/или о любом нарушении (подозрениях о нарушении)
          конфиденциальности своих средств доступа к Аккаунту. Оператор не
          отвечает за возможную потерю или порчу данных, а также другие
          последствия любого характера, которые могут произойти из-за нарушения
          Пользователем положений данной части Соглашения.
        </li>
        <li>
          В случаях, не урегулированных настоящим Соглашением, стороны
          руководствуются действующим законодательством Российской Федерации.
        </li>
        <li>
          При рассмотрении споров устанавливается обязательный досудебный
          претензионный порядок. Срок ответа на претензию устанавливается в
          течение 30 календарных дней с момента получения претензии стороной. К
          претензии должны прилагаться подлинники, либо надлежащим образом
          заверенные копии документов. В случае, если спор не удастся решить в
          претензионном порядке, Стороны согласились, что споры между ними будут
          решаться в судебном порядке.
        </li>
        <li>
          Настоящее Соглашение регулируется и толкуется в соответствии с
          законодательством Российской Федерации.
        </li>
        <li>
          Настоящее Соглашение не распространяется на сервисы сторонних
          разработчиков или поставщиков, социальные сети, услуги, которые могут
          быть доступны через Платформ или интегрированы с ними, равно ссылки на
          них.
        </li>
        <li>
          Оператор имеет право вносить изменения в настоящее Соглашение. При
          внесении изменений в актуальной редакции указывается дата последнего
          обновления. Новая редакция Соглашения вступает в силу с момента его
          размещения на Платформе, если иное не предусмотрено новой редакцией
          Соглашения. Действующая редакция постоянно доступна на Платформе.
        </li>
      </ul>
    </div>
  );
};
