import { usePWAInstall } from "react-use-pwa-install";
import { useEffect, useMemo, useState } from "react";
import { Button } from "../../Button";
import { isAndroid, isIOS, IOSView, isMobileSafari } from "react-device-detect";
import styles from "./PWAInstallComponent.module.scss";
import PWAPrompt from "react-ios-pwa-prompt";

const PWAInstallComponent = () => {
  const install = usePWAInstall();
  

  const [shouldShowPWAPrompt, setShouldShowPWAPrompt] = useState(true);

  const bannerVariantRender = useMemo(() => {
    if (install && isAndroid) {
      return (
        <Button
          variant="yellow-border"
          onClick={install}
          className={styles.installBtn}
        >
          Установить приложение
        </Button>
      );
    }

    if (!install && isAndroid) {
      return null;
    }

    if (isIOS) {
      return (
        <>
          {shouldShowPWAPrompt && (
            <PWAPrompt
              copyTitle="МЮБ"
              copyDescription="Этот вебсайт имеет функционал приложения. Вы можете выполнить установку на ваше устройство"
              copyShareStep="Нажмите 'Поделиться'"
              copyAddToHomeScreenStep="Нажмите 'Добавить на домашний экран'"
              promptOnVisit={1}
              timesToShow={0}
              permanentlyHideOnDismiss={false}
              appIconPath={"../../../../public/logo-24.png"}
              isShown={!localStorage.getItem("pwaPromptDismissed")}
              onClose={() => {
                localStorage.setItem("pwaPromptDismissed", true);
                setShouldShowPWAPrompt(false);
              }}
            />
          )}
        </>
      );
    }
  }, [install, shouldShowPWAPrompt]);

  useEffect(() => {
    if (isIOS) {
      const iOSIsInstalled = window.navigator.standalone === true;

      if (iOSIsInstalled) {
        // Приложение уже установлено, больше не показываем подсказку
        localStorage.setItem("pwaPromptDismissed", true);
        setShouldShowPWAPrompt(false);
      } else {
        // Проверяем, отклонял ли пользователь подсказку ранее
        const isPromptDismissed = localStorage.getItem("pwaPromptDismissed");
        if (!isPromptDismissed) {
          setShouldShowPWAPrompt(true); // Показываем подсказку
        }
      }
    }
  }, []);

  return <>{bannerVariantRender}</>;
};

export default PWAInstallComponent;


