import React from "react";
import { Header } from "./coomponents/Header";
import { AppRouter } from "./coomponents/AppRouter/ui/AppRouter";

function App() {
  return (
    <div className="container">
      <Header />
      <AppRouter />
    </div>
  );
}

export default App;
