import { useMemo } from "react";
import styles from "./ContentBox.module.scss";
import { Label } from "../../Label";
import { DocumentIcon } from "../../../assets/icons/DocumentIcon";
import { PaymentIcon } from "../../../assets/icons/PaymentIcon";
import cn from "classnames";
import { BoxArrow } from "../../../assets/icons/BoxArrow";
import { formattedDateFunc } from "../../../helpers/formattedDateFunc";
import { ProgressBar } from "../../ProgressBar";

export const ContentBox = ({
  text,
  labelText,
  isDocument,
  labelVariant,
  className,
  onClick,
  currentValue,
  maxValue
}) => {
  const contentBoxVariantRender = useMemo(() => {
    if (isDocument) {
      return (
        <div className={styles.contentInner}>
          <DocumentIcon className={styles.documentIcon} />
          <div className={styles.contentInfo}>
            <Label text={labelText} variant={labelVariant} />
            <h3>{text}</h3>
          </div>
        </div>
      );
    } else {
      return (
        <div className={styles.contentInner}>
          <div className={styles.wrapper}>
            <div className={styles.paymentInfo}>
              <PaymentIcon />
              <h3>Счет от {formattedDateFunc(text)}</h3>
            </div>
            <Label text={labelText} variant={labelVariant} />
            <ProgressBar current={currentValue} max={maxValue} />
          </div>
        </div>
      );
    }
  }, [isDocument, labelText, labelVariant, text, currentValue, maxValue]);

  return (
    <div className={cn(styles.contentBox, className)} onClick={onClick}>
      {contentBoxVariantRender}
      <BoxArrow className={styles.boxArrow} />
    </div>
  );
};
