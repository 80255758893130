import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  approval: null
}

const approvalSlice = createSlice({
  name: 'aprovalSlice',
  initialState,
  reducers: {
    addApproval: (state, action) => {
      state.approval = action.payload
    }
  }
})

export const {reducer: approvalSliceReducer} = approvalSlice
export const {actions: approvalSliceActions} = approvalSlice