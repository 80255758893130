import { ContentBox } from "../../ContentBox";
import { useMemo } from "react";
import styles from "./ContentList.module.scss";
import { Link, useNavigate } from "react-router-dom";

export const ContentList = ({ documentsData, paymentsData }) => {
  const navigate = useNavigate();

  const listVariantRender = useMemo(() => {
    if (documentsData) {
      return (
        <>
          {documentsData.map((document) => (
            <ContentBox
              key={document?.id}
              isDocument={true}
              text={document?.name}
              labelText={
                document?.status === "Подписать"
                  ? "Подписать"
                  : document?.status === "Отозван"
                  ? "Отозван"
                  : "Подписан"
              }
              labelVariant={
                document?.status === "Подписать"
                  ? "yellow"
                  : document?.status === "Отозван"
                  ? "gray"
                  : "green"
              }
              onClick={() => navigate(`/documents/${document.id}`)}
            />
          ))}
        </>
      );
    }

    if (paymentsData) {
      return (
        <>
          {paymentsData.map((payment) =>
            payment.is_executed === false ? (
              <Link
                key={payment.id}
                to={payment.qr_code}
                target="_blank"
                rel="norefferrer"
              >
                <ContentBox
                  text={payment.created_at}
                  labelText="Оплатить"
                  labelVariant="yellow"
                  maxValue={payment.total_sum}
                  currentValue={payment.budget}
                />
              </Link>
            ) : (
              <ContentBox
                text={payment.created_at}
                labelText="Оплачен"
                labelVariant="green"
                maxValue={payment.total_sum}
                currentValue={payment.budget}
              />
            )
          )}
        </>
      );
    }

    return null;
  }, [documentsData, paymentsData, navigate]);

  return <div className={styles.listWrapper}>{listVariantRender}</div>;
};
