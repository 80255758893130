export const SuccessIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26 0H14C4 0 0 4 0 14V26C0 36 4 40 14 40H26C36 40 40 36 40 26V14C40 4 36 0 26 0ZM29.4 16.1429L18.8 26.7429C18.4 27.1429 17.8857 27.3429 17.3429 27.3429C16.8 27.3429 16.2857 27.1429 15.8857 26.7429L10.5714 21.4286C9.77143 20.6286 9.77143 19.3429 10.5714 18.5429C11.3714 17.7429 12.6571 17.7429 13.4571 18.5429L17.3143 22.4L26.4857 13.2286C27.2857 12.4286 28.5714 12.4286 29.3714 13.2286C30.1714 14.0286 30.2 15.3429 29.4 16.1429Z"
        fill="#FFD600"
      />
    </svg>
  );
};
