import { configureStore } from "@reduxjs/toolkit";
import { projectApi } from "./projectApi";
import { approvalSliceReducer } from "../pages/Main";

export const store = configureStore({
  reducer: {
    [projectApi.reducerPath]: projectApi.reducer,
    approval: approvalSliceReducer
  },

  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(projectApi.middleware)
})