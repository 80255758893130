export const PaymentIcon = () => {
  return (
    <svg
      width="12"
      height="15"
      viewBox="0 0 12 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.95834 0.416748H3.25001C1.12501 0.416748 0.416672 1.68466 0.416672 3.25008V3.95841V13.8751C0.416672 14.463 1.0825 14.7959 1.55 14.4417L2.76126 13.5351C3.04459 13.3226 3.44126 13.3509 3.69625 13.6059L4.87209 14.7888C5.14834 15.0651 5.60167 15.0651 5.87792 14.7888L7.06792 13.5988C7.31584 13.3509 7.7125 13.3226 7.98875 13.5351L9.2 14.4417C9.6675 14.7888 10.3333 14.4559 10.3333 13.8751V1.83341C10.3333 1.05425 10.9708 0.416748 11.75 0.416748H3.95834ZM6.96875 8.73966H3.78126C3.49084 8.73966 3.25001 8.49883 3.25001 8.20841C3.25001 7.918 3.49084 7.67716 3.78126 7.67716H6.96875C7.25917 7.67716 7.5 7.918 7.5 8.20841C7.5 8.49883 7.25917 8.73966 6.96875 8.73966ZM7.5 5.90633H3.25001C2.95959 5.90633 2.71876 5.6655 2.71876 5.37508C2.71876 5.08466 2.95959 4.84383 3.25001 4.84383H7.5C7.79042 4.84383 8.03125 5.08466 8.03125 5.37508C8.03125 5.6655 7.79042 5.90633 7.5 5.90633Z"
        fill="#FFD600"
      />
    </svg>
  );
};
