export const PaperIcon = () => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.55833 1.1971C8.33624 0.975017 7.95166 1.12668 7.95166 1.43543V3.32585C7.95166 4.11668 8.62333 4.7721 9.44124 4.7721C9.95583 4.77752 10.6708 4.77752 11.2829 4.77752C11.5917 4.77752 11.7542 4.4146 11.5375 4.19793C10.7575 3.41252 9.35999 1.99877 8.55833 1.1971Z"
        fill="#FFD600"
      />
      <path
        d="M11.1042 5.5195H9.53874C8.25499 5.5195 7.20957 4.47408 7.20957 3.19034V1.62492C7.20957 1.327 6.96582 1.08325 6.66791 1.08325H4.37124C2.70291 1.08325 1.35416 2.16659 1.35416 4.10034V8.8995C1.35416 10.8333 2.70291 11.9166 4.37124 11.9166H8.62874C10.2971 11.9166 11.6458 10.8333 11.6458 8.8995V6.06117C11.6458 5.76325 11.4021 5.5195 11.1042 5.5195ZM6.22916 9.6145H4.06249C3.84041 9.6145 3.65624 9.43034 3.65624 9.20825C3.65624 8.98617 3.84041 8.802 4.06249 8.802H6.22916C6.45124 8.802 6.63541 8.98617 6.63541 9.20825C6.63541 9.43034 6.45124 9.6145 6.22916 9.6145ZM7.31249 7.44783H4.06249C3.84041 7.44783 3.65624 7.26367 3.65624 7.04158C3.65624 6.8195 3.84041 6.63533 4.06249 6.63533H7.31249C7.53457 6.63533 7.71874 6.8195 7.71874 7.04158C7.71874 7.26367 7.53457 7.44783 7.31249 7.44783Z"
        fill="#FFD600"
      />
    </svg>
  );
};
