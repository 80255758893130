import styles from "./Documents.module.scss";
import { ButtonArrow } from "../../../assets/icons/ButtonArrow";
import { Button } from "../../../coomponents/Button";
import { useNavigate } from "react-router-dom";
import { Title } from "../../../coomponents/Title";
import { useEffect, useState } from "react";
import { ContentList } from "../../../coomponents/ContentList";
import cn from "classnames";
import { useGetLeadsQuery } from "../../../redux/projectApi";
import { Preloader } from "../../../coomponents/Preloader";
import { Error } from "../../../coomponents/Error/ui/Error";

export const Documents = () => {
  const { data, isLoading, isError } = useGetLeadsQuery(false);
  const {
    data: signedData,
    isLoading: signedStatus,
    isError: signedError,
  } = useGetLeadsQuery(true);
  const [activeTab, setActiveTab] = useState(1);
  const [documents, setDocuments] = useState([]);
  const navigate = useNavigate();

  const allDocuments = () => {
    setDocuments(signedData?.leads_documents);
  };

  const notSignedDocuments = () => {
    setDocuments(data?.leads_documents);
  };

  const signedDocuments = () => {
    const newDocuments = [...signedData.leads_documents].filter(
      (document) => document.status === "Подписан"
    );
    setDocuments(newDocuments);
  };

  useEffect(() => {
    if (signedData?.leads_documents) {
      setDocuments(signedData?.leads_documents);
    }
  }, [signedData]);

  return (
    <div className={styles.documentsWrapper}>
      <Button
        variant="transparent"
        className={styles.documentsBtn}
        onClick={() => navigate(-1)}
      >
        <ButtonArrow />
        Назад
      </Button>
      <Title>Документы</Title>
      <div className={styles.documentsFilters}>
        <Button
          variant={activeTab === 1 ? "yellow" : "gray"}
          onClick={() => {
            setActiveTab(1);
            allDocuments();
          }}
          className={cn(styles.allBtn, {
            [styles.active]: activeTab === 1,
          })}
        >
          Все
        </Button>
        <Button
          variant={activeTab === 2 ? "yellow" : "gray"}
          onClick={() => {
            setActiveTab(2);
            notSignedDocuments();
          }}
          className={activeTab === 2 ? styles.active : ""}
        >
          Подписать
          {data && (
            <span className={styles.infoSpan}>
              {data?.leads_documents?.length}
            </span>
          )}
        </Button>
        <Button
          variant={activeTab === 3 ? "yellow" : "gray"}
          onClick={() => {
            setActiveTab(3);
            signedDocuments();
          }}
          className={activeTab === 3 ? styles.active : ""}
        >
          Подписанные
        </Button>
      </div>
      {isLoading || signedStatus ? (
        <Preloader className={styles.preloader} />
      ) : isError || signedError ? (
        <Error />
      ) : (
        <ContentList documentsData={documents} />
      )}
    </div>
  );
};
