import {Logo} from "../../../assets/icons/Logo";
import styles from "./Header.module.scss";
import {Button} from "../../Button";
import {useLocation, useNavigate} from "react-router-dom";
import {useMemo} from "react";
import {transformNumber} from "../../../helpers/transformNumber";

export const Header = () => {
  const userPhone = transformNumber(localStorage.getItem("phone"));
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const token = localStorage.getItem("token");
  const convertPhone = userPhone
    ? userPhone.split(" ").join("").slice(0, 5) +
    " " +
    " " +
    "***-**-" +
    userPhone.slice(10, 12)
    : null;

  const userProfileRender = useMemo(() => {
    if (
      currentPath !== "/auth" &&
      currentPath !== "/verify" &&
      currentPath !== "/register" &&
      currentPath !== "/approval" &&
      currentPath !== "/agreement" &&
      currentPath !== "/ads-approval"
    ) {
      if (token && userPhone) {
        return (
          <>
            <span>{convertPhone}</span>
            <Button
              variant="transparent"
              className={styles.headerBtn}
              onClick={() => {
                localStorage.removeItem("token");
                localStorage.removeItem("refresh");
                localStorage.removeItem("phone");
                navigate("/auth");
              }}
            >
              Выйти
            </Button>
          </>
        );
      }
    } else {
      return (
        <>
          {currentPath === "/register" ? (
            <Button variant="transparent" onClick={() => navigate("/auth")}>
              Вход
            </Button>
          ) : currentPath === "/auth" ? (
            <Button variant="transparent" onClick={() => navigate("/register")}>
              Регистрация
            </Button>
          ) : (
            currentPath === "/verify" && null
          )}
        </>
      );
    }
  }, [currentPath, token, userPhone, convertPhone, navigate]);

  return (
    <header>
      <div className={styles.headerInner}>
        <Logo/>
        <div className={styles.userInfo}>{userProfileRender}</div>
      </div>
    </header>
  );
};
