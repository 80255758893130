export const DownLoadIcon = () => {
  return (
    <svg
      width="43"
      height="43"
      viewBox="0 0 43 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="42" height="42" rx="7.5" stroke="#F6F7F9" />
      <path
        d="M25.94 18.3999C29.54 18.7099 31.01 20.5599 31.01 24.6099V24.7399C31.01 29.2099 29.22 30.9999 24.75 30.9999H18.24C13.77 30.9999 11.98 29.2099 11.98 24.7399V24.6099C11.98 20.5899 13.43 18.7399 16.97 18.4099"
        stroke="#F6F7F9"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.5 11.5V24.38"
        stroke="#F6F7F9"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M24.85 22.1499L21.5 25.4999L18.15 22.1499"
        stroke="#F6F7F9"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
