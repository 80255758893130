export const ButtonArrow = ({className}) => {
  return (
    <svg
      width="6"
      height="6"
      viewBox="0 0 6 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M5.25279 0.875465H1.49442L6 5.38105L5.38105 6L0.875465 1.49442V5.25279H0V0H5.25279V0.875465Z"
        fill="#F6F7F9"
      />
    </svg>
  );
};
