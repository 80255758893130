import styles from "./AdsApproval.module.scss";
import { Button } from "../../../../coomponents/Button";
import { ButtonArrow } from "../../../../assets/icons/ButtonArrow";
import { useNavigate } from "react-router-dom";
import { Title } from "../../../../coomponents/Title";

export const AdsApproval = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.adsWrapper}>
      <Button
        variant="transparent"
        className={styles.adsBtn}
        onClick={() => navigate(-1)}
      >
        <ButtonArrow />
        Назад
      </Button>
      <Title className={styles.adsTitle}>
        Согласие на получение рекламных сообщений
      </Title>
      <div className={styles.updateDate}>
        <span>Дата последнего обновления: 07 октября 2024 года</span>
      </div>
      <p>
        Пользователь (далее – «Пользователь») при использовании цифровой
        платформы комплексных услуг для прохождения процедуры банкротства для
        физических лиц в режиме онлайн «МЮБ» (далее – «Платформа») во исполнение
        требований Федерального закона «О рекламе» от 13.03.2006 № 38-ФЗ,
        выражает свое согласие на получение информационной и рекламной рассылки
        посредством указания галочки в чек-боксе рядом с текстом «Даю Согласие
        на получение информационной и рекламной информации» по следующим каналам
        связи:
        <ul className={styles.nestedList}>
          <li>- по адресу электронной почты;</li>
          <li>- sms-сообщения;</li>
          <li>
            - сообщения в мессенджерах (Telegram, WhatsApp, Viber, но не
            ограничиваясь);
          </li>
          <li>- push-уведомления;</li>
          <li>- по сетям электросвязи;</li>
          <li>- электронные платежные системы (кошельки).</li>
        </ul>
        <br />
        Пользователь уведомлен и соглашается, что в целях направления ему
        рекламных сообщений Платформа может поручать обработку персональных
        данных Пользователя третьим лицам, которые предоставляют доступ к
        инфраструктуре и/или программному обеспечению и/или платформам для
        рассылки рекламных сообщений.
        <br />
        <br />
        Пользователь может отозвать Согласие, направив письменное заявление об
        отзыве Согласия по электронному адресу: <a href="mailto:support@мюб-списание.рф">support@мюб-списание.рф</a>, либо
        почтой по адресу: 420133, Республика Татарстан , г. Казань , ул.
        Гаврилова 1, а/я 733.
        <br />
        Платформа вправе вносить изменения в настоящее Согласие. При внесении
        изменений в актуальной редакции указывается дата последнего обновления.
        Новая редакция Согласия вступает в силу с момента ее размещения, если
        иное не предусмотрено новой редакцией Согласия.
      </p>
    </div>
  );
};
