import {Title} from "../../../coomponents/Title";
import {ContentList} from "../../../coomponents/ContentList";
import styles from "./Main.module.scss";
import {Button} from "../../../coomponents/Button";
import {useNavigate} from "react-router-dom";
import {PaperIcon} from "../../../assets/icons/PaperIcon";
import {PaymentIcon} from "../../../assets/icons/PaymentIcon";
import {useGetLeadsQuery} from "../../../redux/projectApi";
import {useEffect, useState} from "react";
import {getDeclension} from "../../../helpers/getDeclension";
import {Preloader} from "../../../coomponents/Preloader";
import {Error} from "../../../coomponents/Error/ui/Error";
import PWAInstallComponent from "../../../coomponents/PWAInstallComponent/ui/PWAInstallComponent";


export const Main = () => {
  const {data, isLoading, isError} = useGetLeadsQuery(false);
  const navigate = useNavigate();
  const [documents, setDocuments] = useState([]);
  const [payments, setPayments] = useState([]);

  useEffect(() => {
    if (data?.leads_documents) {
      setDocuments(data?.leads_documents);
    }

    if (data?.payments) {
      setPayments(data?.payments);
    }

  }, [data]);

  if (isLoading) {
    return (
      <div className={styles.mainWrapper}>
        <Preloader className={styles.preloader}/>
      </div>
    )
  }

  if (isError) {
    return (
      <div className={styles.mainWrapper}>
        <Error className={styles.mainError}/>
      </div>
    )
  }


  return (
    <div className={styles.mainWrapper}>
      {(data?.leads_documents?.length > 0 && data?.payments?.length > 0) ||
      data?.leads_documents?.length > 0 ||
      data?.payments?.length > 0 ? (
        <Title>
          Нужно <br/>{" "}
          <span className={styles.titleSpan}>
            {documents && documents.length > 0
              ? documents.length +
              " " +
              getDeclension(documents.length, [
                "подпись",
                "подписи",
                "подписей",
              ])
              : documents.length + " подписей"}{" "}
            <br/> и{" "}
            {payments && payments.length > 0
              ? payments.length +
              " " +
              getDeclension(payments.length, ["оплата", "оплаты", "оплат"])
              : payments.length + " оплат"}
          </span>
        </Title>
      ) : (
        <Title>
          Все подписано <br/> и оплачено
        </Title>
      )}
      <div className={styles.documentsBlock}>
        <h3 className={styles.blockTitle}>Документы</h3>
        <ContentList documentsData={documents}/>
        <Button
          variant="gray"
          className={styles.blockBtn}
          onClick={() => navigate("/documents")}
        >
          <PaperIcon/>
          Все документы
        </Button>
      </div>
      <div className={styles.paymentsBlock}>
        <h3 className={styles.blockTitle}>Счета</h3>
        <ContentList paymentsData={payments}/>
        <Button
          variant="gray"
          className={styles.blockBtn}
          onClick={() => navigate("/payments")}
        >
          <PaymentIcon/>
          Все счета
        </Button>
      </div>
      <PWAInstallComponent/>
    </div>
  );
};
