export const BoxArrow = ({className}) => {
  return (
    <svg
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M1.36988 1.60502H8.26023L0 9.86525L1.13475 11L9.39498 2.73977V9.63012H11V0H1.36988V1.60502Z"
        fill="#404040"
      />
    </svg>
  );
};
