import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ButtonArrow } from "../../../assets/icons/ButtonArrow";
import { Button } from "../../../coomponents/Button";
import { Input } from "../../../coomponents/Input";
import { Title } from "../../../coomponents/Title";
import styles from "./Verify.module.scss";
import { useEffect, useState } from "react";
import { useCheckVerificationMutation, useLazyGetSignedCodeQuery } from "../../../redux/projectApi";
import { useUserAuthMutation } from "../../../redux/projectApi";
import { transformNumber } from "../../../helpers/transformNumber";

export const Verify = () => {
  const [code, setCode] = useState('')
  const [seconds, setSeconds] = useState(59);
  const [startTimer, setStartTimer] = useState(false)
  const userPhone = localStorage.getItem('phone')
  const navigate = useNavigate();
  const [userVerify, {isSuccess, data, isError}] = useCheckVerificationMutation()
  const [userAuth] = useUserAuthMutation()
  const location = useLocation()
  const currentPath = location.pathname
  const [clickCount, setClickCount] = useState(0)
  const {file, id} = useParams()
  const [getSignedCode] = useLazyGetSignedCodeQuery()
  const [error, setError] = useState('')
  const documentSignedLink = process.env.REACT_APP_API_DOCUMENT_SIGNED
  const [isEvent, setIsEvent] = useState(false)
  

  const documentSignedSubmit = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${documentSignedLink + 'signature/verify_code'}`, {
        method: 'POST',
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
          "Accept": "application/json"
        },
        body: JSON.stringify({
          code: code.split(' ').join('')
        })
      });
  
      if (!response.ok) {
        setError(response.status)
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      if(response.ok){
        navigate(`/success/${file}/${id}`)
      }
  
      const data = await response.json();

      return data

    } catch (error) {
      console.error('Error:', error);
    }
  };
  

  const handleVerify = async() => {
    if(code.trim() !== '' && userPhone && currentPath === '/verify'){
      await userVerify({phone: transformNumber(userPhone), code: code.split(' ').join('')})
    }

    if(code.trim() !== '' && currentPath === `/check/${file}/${id}`){
      documentSignedSubmit().catch(error => setError(error.message))
    }

    setClickCount(prev => prev + 1)
  }

  const handleUserAuth = async() => {
    if(currentPath === `/check/${file}/${id}`){
      await getSignedCode(id)
    }

    if(currentPath === '/verify'){
      await userAuth({phone: userPhone})
    }

    setStartTimer(true)
    setIsEvent(true)
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const otp = formData.get('otp');
  };

  useEffect(() => {
    if(isSuccess && currentPath === '/verify'){
      navigate('/')
      setClickCount(0)
    }

    if(isSuccess && currentPath === `/check/${file}/${id}`){
      navigate(-1)
      setClickCount(0)
    }

    if(data){
      localStorage.setItem('token', data.access_token)
      localStorage.setItem('refresh', data.refresh_token)
    }
  }, [data, isSuccess, navigate, currentPath, file, id])

  useEffect(() => {
    if (seconds > 0 && startTimer) {
      const intervalId = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
      return () => clearInterval(intervalId);
    } 

    if(seconds === 0){
      setStartTimer(false)
      setSeconds(59)
    }

  }, [seconds, startTimer])

  useEffect(() => {
    if(clickCount > 5){
      if(file && id){
        navigate(`/error/${file}/${id}`)
      } else {
        navigate('/error')
      }
    }
  }, [clickCount, navigate, file, id])

  

  return (
    <div className={styles.verifyWrapper}>
      <Button
        variant="transparent"
        className={styles.verifyBtn}
        onClick={() => {
          if(!currentPath.includes('check')){
            navigate('/auth')
          } else {
            navigate(-1)
          }
        }}
      >
        <ButtonArrow />
        Назад
      </Button>
      <Title>Введите код <br /> из смс</Title>
      <p className={styles.verifyDescr}>Отправили на {userPhone}</p>
      <div className={styles.codeBlock}>
        <form id="form" onSubmit={handleSubmit} noValidate={true}>
        {currentPath === '/verify'
          ? <Input mask="_ _  _ _" alwaysShowMask={true} isEvent={isEvent} isCodeField={true} setIsEvent={setIsEvent} placeholder="_ _  _ _" value={code} maskChar={'_'} setValue={setCode} error={isError} autocomplete="one-time-code" />
          : <Input mask="_ _ _  _ _ _" alwaysShowMask={true} isEvent={isEvent} setIsEvent={setIsEvent} file={file} id={id} isCodeField={true} placeholder="_ _ _  _ _ _" value={code} maskChar={'_'} setValue={setCode} error={error} autocomplete="one-time-code" />
        }
        </form>
        <div>
          <Button variant="yellow" onClick={handleVerify} type='submit' form='form'>
            {currentPath === '/verify' ? 'Далее' : 'Подписать'}
          </Button>
          <Button variant="gray" onClick={handleUserAuth} disabled={startTimer ? true : false}>{startTimer ? `Отправить еще код 00:${seconds >= 10 ? seconds : '0' + seconds}` : 'Отправить еще код'}</Button>
        </div>
      </div>
      <p className={styles.verifyInfo}>
        Чтобы изменить номер телефона, позвоните <span> вашему менеджеру <br />
        <a className={styles.verifyLink} href="tel:88007707473">8-800-770-74-73</a></span>
      </p>
    </div>
  );
};
