import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

const baseAppUrl = process.env.REACT_APP_API_BASE_URL;

const refreshToken = async () => {
  const refreshToken = localStorage.getItem("refresh");

  const response = await fetch(baseAppUrl + "users/auth/refresh", {
    method: "POST",
    headers: {
      Authorization: `Bearer ${refreshToken}`,
    },
  });

  if (!response.ok) {
    throw new Error("Failed to refresh token");
  }

  const data = await response.json();
  localStorage.setItem("token", data.access_token);
  return data.access_token;
};

const baseQuery = fetchBaseQuery({
  baseUrl: baseAppUrl,
  prepareHeaders: (headers) => {
    const token = localStorage.getItem("token");
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && (result.error.status === 403 || result.error.status === 401)) {
    // Попытка обновления токена
    try {
      await refreshToken();
      result = await baseQuery(args, api, extraOptions);
    } catch (error) {
      // Обработка ошибки обновления токена
      const isAuthPage =
        window.location.pathname.includes("/auth") ||
        window.location.pathname.includes("/register");
      if (!isAuthPage) {
        window.location.replace("/auth");
      }
      console.error("Token refresh failed", error);
      localStorage.removeItem("token");
      localStorage.removeItem("refresh");
      localStorage.removeItem("phone");
    }
  }

  return result;
};

export const projectApi = createApi({
  reducerPath: "projectApi",
  tagTypes: ["Leads"],
  baseQuery: baseQueryWithReauth,
  credentials: "include",
  endpoints: (build) => ({
    getLeads: build.query({
      query: (all) => `users/documents?all=${all}`,
      refetchOnMountOrArgChange: true,
    }),

    getRetrieve: build.query({
      query: (number) => `users/retrieve?init=${number}`,
    }),

    getDocument: build.query({
      query: (id) => `documents/${id}`,
      invalidatesTags: ["Leads"],
    }),

    getPayment: build.query({
      query: (id) => `payments/${id}`,
    }),

    getSignedCode: build.query({
      query: (id) => `documents/signed/${id}`,
    }),

    userAuth: build.mutation({
      query: (body) => ({
        url: "users/login",
        method: "POST",
        body,
      }),
    }),

    userRegister: build.mutation({
      query: (body) => ({
        url: "users/register",
        method: "POST",
        body,
      }),
    }),

    checkVerification: build.mutation({
      query: (body) => ({
        url: "users/verify_code",
        method: "POST",
        body,
      }),
    }),

    updateApproval: build.mutation({
      query: (body) => ({
        url: "users/profile/update",
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Leads"],
    }),

    refreshToken: build.mutation({
      query: (body) => ({
        url: "userauth/refresh/",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useGetLeadsQuery,
  useLazyGetLeadsQuery,
  useUserAuthMutation,
  useUserRegisterMutation,
  useCheckVerificationMutation,
  useRefreshTokenMutation,
  useGetRetrieveQuery,
  useGetDocumentQuery,
  useLazyGetDocumentQuery,
  useGetPaymentQuery,
  useLazyGetSignedCodeQuery,
  useUpdateApprovalMutation,
} = projectApi;
