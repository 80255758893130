import { useNavigate, useParams } from "react-router-dom";
import styles from "./Document.module.scss";
import { ButtonArrow } from "../../../assets/icons/ButtonArrow";
import { Button } from "../../../coomponents/Button";
import { Title } from "../../../coomponents/Title";
import { DownLoadIcon } from "../../../assets/icons/DownloadIcon";
import {
  useLazyGetDocumentQuery,
  useLazyGetSignedCodeQuery,
  useUpdateApprovalMutation,
} from "../../../redux/projectApi";
import { useEffect, useState } from "react";
import { Document as DocumentComponent, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { Preloader } from "../../../coomponents/Preloader";
import { Error } from "../../../coomponents/Error/ui/Error";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export const Document = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [getDoc, { data, isLoading, isError }] = useLazyGetDocumentQuery();
  const token = localStorage.getItem("token");
  const [documentFile, setDocumentFile] = useState(null);
  const [numPages, setNumPages] = useState(0);
  const [getSignedCode, { isError: signedError }] = useLazyGetSignedCodeQuery();
  const [checkError, setCheckError] = useState(false);
  const [updateApproval] = useUpdateApprovalMutation();
  const [errorDocument, setErrorDocument] = useState(false);

  const signedDocument = async () => {
    getSignedCode(id);
    await updateApproval({ consent_given: true });
    localStorage.setItem("approval", true);
    navigate(`/check/document/${id}`);
  };

  const onDocumentLoadSuccess = (numPages) => {
    setNumPages(numPages);
  };

  const onDocumentLoadError = () => {
    setErrorDocument(true);
  };

  const downloadDocument = () => {
    const link = document.createElement("a");
    link.href = documentFile;
    link.download = data?.name; // Название файла для сохранения
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(documentFile);
  };

  useEffect(() => {
    getDoc(id);
  }, [id, getDoc]);

  useEffect(() => {
    const getFile = async () => {
      try {
        const response = await fetch(data?.file, {
          headers: {
            Authorization: `Bearer ${token}`,
            accept: "blob",
          },
        });

        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        return url;
      } catch (error) {
        console.log(error);
      }
    };

    getFile().then((url) => setDocumentFile(url));
  }, [data, token]);

  useEffect(() => {
    if (signedError) {
      setCheckError(true);
    }
  }, [signedError]);

  if (isLoading) {
    return (
      <div className={styles.documentWrapper}>
        <Preloader className={styles.documentPreloader} />
      </div>
    );
  }

  return (
    <div className={styles.documentWrapper}>
      <Button
        variant="transparent"
        className={styles.documentBtn}
        onClick={() => navigate(-1)}
      >
        <ButtonArrow />
        Назад
      </Button>
      {!isError ? (
        <>
          <Title className={styles.documentTitle}>{data?.name}</Title>

          <div className={styles.documentInfoWrapper}>
            <div className={styles.documentsButtons}>
              <Button
                variant={
                  data && data?.status === "Подписан"
                    ? "green"
                    : data?.status === "Отозван"
                    ? "gray"
                    : "yellow"
                }
                className={styles.documentsBtn}
                disabled={
                  (data && data?.status === "Подписан") ||
                  data?.status === "Отозван"
                    ? true
                    : false
                }
                onClick={signedDocument}
              >
                {data && data?.status === "Подписан"
                  ? "Подписано"
                  : data?.status === "Отозван"
                  ? "Отозван"
                  : "Подписать"}
              </Button>
              <Button variant="transparent" onClick={downloadDocument}>
                <DownLoadIcon />
              </Button>
            </div>
            {errorDocument ? (
              <p className={styles.docError}>Ошибка загрузки документа</p>
            ) : (
              <DocumentComponent
                file={documentFile}
                renderMode="svg"
                onLoadSuccess={onDocumentLoadSuccess}
                onDocumentLoadError={onDocumentLoadError}
                className={styles.documentComponent}
              >
                {Array.from(new Array(numPages.numPages), (el, index) => (
                  <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                ))}
              </DocumentComponent>
            )}
          </div>
        </>
      ) : (
        <Error />
      )}
    </div>
  );
};
