import styles from './Error.module.scss'
import { Button } from "../../../coomponents/Button";
import { ButtonArrow } from "../../../assets/icons/ButtonArrow";
import { useNavigate } from 'react-router-dom';
import { ErrorIcon } from '../../../assets/icons/ErrorIcon';
import { Title } from '../../../coomponents/Title';

export const Error = () => {
  const navigate = useNavigate()

  return(
    <div className={styles.errorWrapper}>
      <Button
        variant="transparent"
        className={styles.errorBtn}
        onClick={() => navigate(-2)}
      >
        <ButtonArrow />
        Назад
      </Button>

      <div className={styles.titleBox}>
        <ErrorIcon />
        <Title className={styles.errorTitle}>
          Вы использовали <span>слишком много попыток</span>
        </Title>
        <p className={styles.errorDescr}>Чтобы изменить номер телефона <br /> позвоните <a href='tel:88001234567'>вашему менеджеру</a></p>
      </div>

    </div>
  )
}