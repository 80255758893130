import styles from './Label.module.scss'
import cn from 'classnames'

export const Label = ({variant, text}) => {
  return(
    <div className={cn(styles.label, {
      [styles.yellow]: variant === 'yellow',
      [styles.green]: variant === 'green',
      [styles.gray]: variant === 'gray'
    })}>{text}</div>
  )
}