export const ErrorIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="10" fill="#FFD600" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M28.4142 11.5858C29.1953 12.3668 29.1953 13.6332 28.4142 14.4142L14.4142 28.4142C13.6332 29.1953 12.3668 29.1953 11.5858 28.4142C10.8047 27.6332 10.8047 26.3668 11.5858 25.5858L25.5858 11.5858C26.3668 10.8047 27.6332 10.8047 28.4142 11.5858Z"
        fill="#050505"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.5858 11.5858C12.3668 10.8047 13.6332 10.8047 14.4142 11.5858L28.4142 25.5858C29.1953 26.3668 29.1953 27.6332 28.4142 28.4142C27.6332 29.1953 26.3668 29.1953 25.5858 28.4142L11.5858 14.4142C10.8047 13.6332 10.8047 12.3668 11.5858 11.5858Z"
        fill="#050505"
      />
    </svg>
  );
};
