import styles from "./Approval.module.scss";
import { ButtonArrow } from "../../../assets/icons/ButtonArrow";
import { Button } from "../../../coomponents/Button";
import { useNavigate } from "react-router-dom";
import { Title } from "../../../coomponents/Title";

export const Approval = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.approvalWrapper}>
      <Button
        variant="transparent"
        className={styles.approvalBtn}
        onClick={() => navigate(-1)}
      >
        <ButtonArrow />
        Назад
      </Button>

      <Title>ПОЛИТИКА ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</Title>
      <div className={styles.updateDate}>
        <span>Дата последнего обновления: 07 октября 2024 года</span>
      </div>
      <h3>1. ОБЩИЕ ПОЛОЖЕНИЯ</h3>
      <ul>
        <li>
          Настоящая Политика обработки персональных данных (далее – «Политика»)
          определяет подход общества с ограниченной ответственностью «МЮБ
          Списание», ИНН 1685017276, адрес места нахождения: г. Казань,
          ул.Гаврилова 1, пом.14 (далее - «Оператор») к обработке персональных
          данных (далее – «Персональные данные») пользователей (далее –
          «Субъекты персональных данных») при использовании цифровой платформы
          комплексных услуг для прохождения процедуры банкротства для физических
          лиц в режиме онлайн «МЮБ» (далее – «Платформа»).
        </li>
        <li>
          Использование сервисов Платформы означает безоговорочное согласие
          Субъекта персональных данных с настоящей Политикой и указанными в ней
          условиями обработки Персональных данных. Если Субъект персональных
          данных не согласен с настоящей Политикой, то ему не следует
          пользоваться Платформой.
        </li>
        <li>
          Настоящая Политика разработана во исполнение требований Федерального
          закона от 27.07.2006 N 152-ФЗ «О персональных данных» (далее – «Закон
          о персональных данных»).
        </li>
        <li>
          Понятия, содержащиеся в Законе о персональных данных, используются в
          настоящей Политике с аналогичным значением.
        </li>
        <li>
          Оператор обеспечивает обработку Персональных данных с использованием
          баз данных, находящихся на территории Российской Федерации.
        </li>
        <li>
          Действие настоящей Политики распространяется на все операции,
          совершаемые Оператором с Персональными данными с использованием
          средств автоматизации или без их использования.
        </li>
        <li className={styles.fullList}>
          <ul style={{ rowGap: 0 }}>
            <li>Основные права и обязанности Оператора.</li>
          </ul>
          <br />
          <span className={styles.approvalSpan}>
            1.7.1. Оператор имеет право:
            <ul>
              <li>
                получать от Субъекта персональных данных достоверные информацию
                и/или документы, содержащие Персональные данные;
              </li>
              <li>
                требовать от Субъекта персональных данных своевременного
                уточнения предоставленных Персональных данных.
              </li>
            </ul>
          </span>
          <span className={styles.approvalSpan}>
            1.7.2. Оператор обязан:
            <ul>
              <li>
                обрабатывать Персональные данные в порядке, установленном
                действующим законодательством Российской Федерации;
              </li>
              <li>
                рассматривать обращения Субъекта персональных данных (его
                законного представителя) по вопросам обработки Персональных
                данных и давать мотивированные ответы;
              </li>
              <li>
                предоставлять Субъекту персональных данных (его законному
                представителю) возможность безвозмездного доступа к его
                Персональным данным;
              </li>
              <li>
                принимать меры по уточнению, уничтожению Персональных данных
                Субъекта персональных данных в связи с его (его законного
                представителя) обращением с законными и обоснованными
                требованиями;
              </li>
              <li>
                организовывать защиту Персональных данных в соответствии с
                требованиями законодательства Российской Федерации.
              </li>
            </ul>
          </span>
        </li>
      </ul>
      <p>
        1.8. Основные права и обязанности Субъектов персональных данных:
        <span className={styles.approvalSpan}>
          1.8.1. Субъекты персональных данных имеют право:
          <ul>
            <li>
              на полную информацию об их Персональных данных, обрабатываемых
              Оператором;
            </li>
            <li>
              на доступ к их Персональным данным, включая право на получение
              копии любой записи, содержащей их Персональные данные, за
              исключением случаев, предусмотренных федеральным законом;
            </li>
            <li>
              на уточнение их Персональных данных, их блокирование или
              уничтожение в случаях, если Персональные данные являются
              неполными, устаревшими, неточными, незаконно полученными или не
              являются необходимыми для заявленной цели обработки;
            </li>
            <li>на отзыв согласия на обработку Персональных данных;</li>
            <li>
              на принятие предусмотренных законом мер по защите своих прав;
            </li>
            <li>
              на осуществление иных прав, предусмотренных законодательством
              Российской Федерации.
            </li>
          </ul>
        </span>
        <span className={styles.approvalSpan}>
          1.8.2. Субъекты персональных данных обязаны:
          <ul>
            <li>предоставлять Оператору только достоверные данные о себе;</li>
            <li>
              предоставлять документы, содержащие Персональные данные в объеме,
              необходимом для цели обработки;
            </li>
            <li>
              сообщать Оператору об уточнении (обновлении, изменении) своих
              Персональных данных
            </li>
          </ul>
        </span>
        <span>
          1.8.3. Лица, передавшие Оператору недостоверные сведения о себе, либо
          сведения о другом Субъекте персональных данных без согласия
          последнего, несут ответственность в соответствии с законодательством
          Российской Федерации.
        </span>
      </p>
      <h3>
        2. ОБЪЕМ И КАТЕГОРИИ ОБРАБАТЫВАЕМЫХ ПЕРСОНАЛЬНЫХ ДАННЫХ, КАТЕГОРИИ
        СУБЪЕКТОВ ПЕРСОНАЛЬНЫХ ДАННЫХ
      </h3>
      <span className={styles.approvalSpan}>
        2.1. Оператор может обрабатывать Персональные данные следующих Субъектов
        персональных данных:
        <ul>
          <li>клиенты и контрагенты Оператора (физические лица);</li>
          <li>
            представители/работники клиентов и контрагентов Оператора
            (юридических лиц);
          </li>
          <li>посетители Платформы.</li>
        </ul>
      </span>
      <span className={styles.approvalSpan}>
        2.2. К Персональным данным, обрабатываемым Оператором, относятся:
        <ul>
          <li>фамилия, имя, отчество Субъекта персональных данных;</li>
          <li>место проживания (регион/город);</li>
          <li>номер мобильного телефона;</li>
          <li>адрес электронной почты (e-mail);</li>
          <li>
            история запросов и просмотров на Платформе и его сервисах (для
            посетителей Платформы);
          </li>
          <li>
            информация из официальных источников о ходе и результатах судебного
            разбирательства в отношении или связанного с Пользователем;
          </li>
          <li>
            прочая информация согласно Перечня в Приложении №1 к настоящей
            Политике (запрашиваемая информация не является исчерпывающей и может
            быть дополнена и/или изменена Оператором в одностороннем порядке в
            случае необходимости).
          </li>
        </ul>
      </span>
      <div className={styles.approvalInfo}>
        <span>
          2.3. Оператор обеспечивает соответствие содержания и объема
          обрабатываемых Персональных данных заявленным целям обработки и, в
          случае необходимости, принимает меры по устранению их избыточности по
          отношению к заявленным целям обработки.
        </span>
        <span>
          2.4. Оператор обрабатывает биометрические Персональные данные при
          условии письменного согласия соответствующих Субъектов персональных
          данных и при условии соблюдения требований, предусмотренных
          законодательством Российской Федерации.
        </span>
        <span>
          2.5 Обработка специальных категорий Персональных данных, касающихся
          расовой, национальной принадлежности, политических взглядов,
          религиозных или философских убеждений, интимной жизни, Оператором не
          осуществляется.
        </span>
        <span>
          2.6 Трансграничная передача Персональных данных Оператором не
          осуществляется.
        </span>
      </div>
      <h3>3. ЦЕЛИ СБОРА ПЕРСОНАЛЬНЫХ ДАННЫХ</h3>

      <span className={styles.approvalSpan}>
        Персональные данные обрабатываются Оператором в следующих целях:
        <ul>
          <li>предоставление доступа ко всей функциональности Платформы;</li>
          <li>
            предоставление Субъектам персональных данных сервисов и услуг
            Оператора, а также информации о разработке Оператором новых
            продуктов и услуг, в том числе рекламного характера;
          </li>
          <li>
            заключение с Субъектами персональных данных договоров и их
            дальнейшего исполнения;
          </li>
          <li>
            проведение Оператором акций, опросов, интервью, тестирований и
            исследований на Платформе;
          </li>
          <li>
            анализ и исследование предпочтений Субъекта персональных данных в
            целях улучшения сервиса Платформы;
          </li>
          <li>
            проведение Оператором или уполномоченными третьими лицами
            статистических и маркетинговых исследований на основе обезличенных
            данных;
          </li>
          <li>
            обратная связь с Субъектами персональных данных, в том числе
            обработка их запросов и обращений, информирование о работе
            Платформы;
          </li>
          <li>
            контроль и улучшение качества услуг и сервисов Оператора, в том
            числе предложенных на Платформы;
          </li>
          <li>
            осуществление иных функций, полномочий и обязанностей, возложенных
            на Оператора законодательством Российской Федерации.
          </li>
        </ul>
      </span>
      <span>
        В процессе использования Платформы Субъектами персональных данных
        Оператору автоматически передается следующая информация, которая не
        используется для установления личности: IP-адрес устройства, информация
        из Cookies, информация об интернет-браузере (или ином приложении, с
        помощью которого осуществляется доступ к Платформе), время доступа к
        Платформе, а также техническая информация об устройстве Субъекта
        персональных данных, с помощью которого осуществляется доступ (в том
        числе идентификационный номер устройства, модель устройства, год выпуска
        устройства, установленная на устройстве версия ПО).
      </span>
      <h3>4. ПОРЯДОК И УСЛОВИЯ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</h3>
      <span className={styles.approvalSpan}>
        4.1. При обработке Персональных данных Оператор вправе применять
        следующие виды обработки Персональных данных:
        <ul>
          <li>неавтоматизированная обработка Персональных данных;</li>
          <li>
            автоматизированная обработка Персональных данных с передачей
            полученной информации по информационно-телекоммуникационным сетям
            или без таковой;
          </li>
          <li>смешанная обработка персональных данных.</li>
        </ul>
      </span>
      <ul>
        <li>
          Перечень действий, совершаемых Оператором с Персональными данными:
          сбор, систематизация, накопление, хранение, уточнение (обновление,
          изменение), использование, распространение (в том числе передача),
          обезличивание, блокирование, уничтожение, а также осуществление любых
          иных действий в соответствии с действующим законодательством
          Российской Федерации.
        </li>
        <li>
          Обработка Персональных данных осуществляется Оператором при условии
          получения согласия Субъекта персональных данных (далее – «Согласие»),
          за исключением установленных законодательством Российской Федерации
          случаев, когда обработка Персональных данных может осуществляться без
          такого Согласия. Согласие подтверждается проставлением галочки
          (чекбокс) в форме и/или нажатием кнопки, размещенной на Платформе.
        </li>
        <li>
          Субъект персональных данных принимает решение о предоставлении его
          Персональных данных и дает Согласие свободно, своей волей и в своем
          интересе.
        </li>
        <li>
          Условием прекращения обработки Персональных данных может являться
          достижение целей обработки Персональных данных, истечение срока
          действия Согласия или отзыв Согласия Субъектом персональных данных, а
          также выявление неправомерной обработки Персональных данных.
        </li>
        <li>
          Оператор при обработке Персональных данных принимает или обеспечивает
          принятие необходимых правовых, организационных и технических мер для
          защиты Персональных данных от неправомерного или случайного доступа к
          ним, уничтожения, изменения, блокирования, копирования,
          предоставления, распространения Персональных данных, а также от иных
          неправомерных действий в отношении Персональных данных.
        </li>
        <li>
          Хранение Персональных данных осуществляется в форме, позволяющей
          определить Субъекта персональных данных в течение срока не дольше, чем
          этого требуют цели обработки Персональных данных, кроме случаев, когда
          срок хранения Персональных данных установлен федеральным законом,
          договором, стороной по которому является Субъект персональных данных.
        </li>
      </ul>
      <h3>
        5. АКТУАЛИЗАЦИЯ, ИСПРАВЛЕНИЕ, УДАЛЕНИЕ И УНИЧТОЖЕНИЕ ПЕРСОНАЛЬНЫХ
        ДАННЫХ, ОТВЕТЫ НА ЗАПРОСЫ СУБЪЕКТОВ ПЕРСОНАЛЬНЫХ ДАННЫХ НА ДОСТУП К
        ПЕРСОНАЛЬНЫМ ДАННЫМ
      </h3>
      <ul>
        <li>
          В случае подтверждения факта неточности Персональных данных или
          неправомерности их обработки Персональные данные подлежат их
          актуализации Оператором, или их обработка должна быть прекращена
          соответственно.
        </li>
        <li>
          Факт неточности Персональных данных или неправомерности их обработки
          может быть установлен Оператором, Субъектом персональных данных либо
          компетентными государственными органами Российской Федерации.
        </li>
        <li>
          По письменному запросу Субъекта персональных данных или его
          представителя Оператор обязан сообщить информацию об осуществляемой им
          обработке Персональных данных указанного субъекта. Запрос должен
          содержать номер основного документа, удостоверяющего личность Субъекта
          персональных данных и его представителя, сведения о дате выдачи
          указанного документа и выдавшем его органе, сведения, подтверждающие
          участие Субъекта персональных данных в отношениях с Оператором (номер
          договора, дата заключения договора, условное словесное обозначение и
          (или) иные сведения), либо сведения, иным образом подтверждающие факт
          обработки Персональных данных Оператором, подпись Субъекта
          персональных данных или его представителя. Запрос может быть направлен
          в форме электронного документа и подписан электронной подписью в
          соответствии с законодательством Российской Федерации.
        </li>
        <li>
          Если в запросе Субъекта персональных данных не отражены все
          необходимые сведения или субъект не обладает правами доступа к
          запрашиваемой информации, то ему направляется мотивированный отказ.
        </li>
        <li>
          Субъект персональных данных вправе требовать от Оператора уточнения
          его Персональных данных, их блокирования или уничтожения в случае,
          если Персональные данные являются неполными, устаревшими, неточными,
          незаконно полученными или не являются необходимыми для заявленной цели
          обработки, а также принимать предусмотренные законом меры по защите
          своих прав.
        </li>
        <li>
          Согласие может быть отозвано путем письменного уведомления,
          направленного в адрес Оператора заказным почтовым отправлением либо в
          форме электронного документа и подписан электронной подписью в
          соответствии с законодательством Российской Федерации.
        </li>
        <li>
          Оператор обязуется уничтожить Персональные данные в течение 30 дней с
          момента получения уведомления об отзыве, при этом все услуги в адрес
          Субъекта персональных данных автоматически прекращаются в момент
          уничтожения его Персональных данных.
        </li>
        <li>
          В случае отзыва Субъектом персональных данных Согласия на обработку
          Персональных данных Оператор вправе продолжить обработку Персональных
          данных без Согласия на обработку при наличии оснований, указанных в
          законодательстве Российской Федерации.
        </li>
        <li>
          При достижении целей обработки Персональных данных, а также в случае
          отзыва Субъектом персональных данных Согласия, Персональные данные
          подлежат уничтожению, если:
          <ul style={{ marginLeft: "20px", marginTop: "5px" }}>
            <li>
              Оператор не вправе осуществлять обработку без Согласия Субъекта
              персональных данных;
            </li>
            <li>
              иное не предусмотрено договором, стороной, выгодоприобретателем
              или поручителем по которому является Субъект персональных данных;
            </li>
            <li>иное не предусмотрено федеральным законом.</li>
          </ul>
        </li>
      </ul>
      <h3>6. ФАЙЛЫ КУКИ</h3>
      <div className={styles.approvalInfo}>
        <span>
          6.1. Оператор использует файлы cookies (далее - «Файлы cookies») для
          автоматического сбора, измерения и анализа страниц, на которые Субъект
          персональных данных переходит, и того как использует Платформа. Это
          необходимо для улучшения пользовательского опыта. Файлы cookies
          позволяют Оператору предоставлять определенные функции и возможности.
          Используя Платформа Субъект персональных данных соглашается на
          использование Оператором Файлов cookies. <br /> <br /> Субъект
          персональных данных может отключить и удалить Файлы cookies в
          настройках своего браузера. Отказ действует в отношении конкретного
          браузера или устройства, поэтому Субъекту персональных данных может
          потребоваться отказаться отдельно в каждом браузере и на каждом
          устройстве. Если Субъект персональных данных решит отключить или
          удалить Файлы cookies, некоторые функции Платформы могут перестать
          работать.
        </span>
        <span>
          6.4. Используя Платформа Пользователь соглашается на получение push
          уведомлений, которые всегда может отключить в настройках приложений.
        </span>
      </div>
      <h3>7. ЗАКЛЮЧИТЕЛЬНЫЕ УСЛОВИЯ</h3>
      <div className={styles.approvalInfo}>
        <span>
          7.1. Все отношения, касающиеся обработки Персональных данных, не
          получившие отражения в настоящей Политике, регулируются согласно
          законодательства Российской Федерации.
        </span>
        <span>
          7.2. Настоящая Политика не распространяется на программное обеспечение
          и мобильные приложения, а также сервисы сторонних разработчиков или
          поставщиков, социальные сети, услуги, которые могут быть доступны
          через Платформа или интегрированы с ними, равно ссылки на них (далее –
          «Сторонние сервисы»). Обработка Персональных данных в этих случаях
          находится вне контроля Оператора. Такие Сторонние сервисы являются
          самостоятельными операторами данных и действуют на основании
          собственных политик.
        </span>
      </div>
      <ul style={{ marginTop: "10px" }}>
        <li>
          Оператор имеет право вносить изменения в настоящую Политику. При
          внесении изменений в актуальной редакции указывается дата последнего
          обновления. Новая редакция Политики вступает в силу с момента ее
          размещения на Платформе, если иное не предусмотрено новой редакцией
          Политики. Действующая редакция постоянно доступна на Платформе.
        </li>
      </ul>
      <h4>Приложение №1.</h4>
      <p className={styles.approveDescr}>
        Перечень документов и информации, необходимых для предоставления
        Субъектом персональных данных для оказания Оператором услуг:
      </p>
      <table className={styles.approveTable}>
        <thead>
          <tr>
            <th>№</th>
            <th>Наименование</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>Копия паспорта (все заполненные страницы)</td>
          </tr>
          <tr>
            <td>2</td>
            <td>Копия СНИЛС</td>
          </tr>
          <tr>
            <td>3</td>
            <td>Копия ИНН</td>
          </tr>
          <tr>
            <td>4</td>
            <td>Копия свидетельства о браке (по всем бракам)</td>
          </tr>
          <tr>
            <td>5</td>
            <td>Копия свидетельства о разводе (по всем разводам)</td>
          </tr>
          <tr>
            <td>6</td>
            <td>
              Копия свидетельство о рождении (по каждому несовершеннолетнему
              ребенку)
            </td>
          </tr>
          <tr>
            <td>7</td>
            <td>Брачный договор (при наличии)</td>
          </tr>
          <tr>
            <td>8</td>
            <td>Копия паспорта действующего супруга</td>
          </tr>
          <tr>
            <td>9</td>
            <td>Копия СНИЛС действующего супруга</td>
          </tr>
          <tr>
            <td>10</td>
            <td>Копия трудовой книжки заверенная работодателем</td>
          </tr>
          <tr>
            <td>11</td>
            <td>Справка 2 НДФЛ за предыдущие 3 года</td>
          </tr>
          <tr>
            <td>12</td>
            <td>Копия всех правоустанавливающих документов на собственность</td>
          </tr>
          <tr>
            <td>13</td>
            <td>
              Справка БКИ (ГОСУСЛУГИ РФ, СБЕРБАНК, БКИ, ОКБ, Скоринг Бюро)
            </td>
          </tr>
          <tr>
            <td>14</td>
            <td>
              Информация о сделках за последние 3 года копии всех документов по
              сделкам с имуществом за последние 3 года (договоры купли-продажи,
              дарение, залог, соглашение о разделе имущества и пр.){" "}
            </td>
          </tr>
          <tr>
            <td>15</td>
            <td>
              Сведения о состоянии индивидуального лицевого счёта
              застрахованного лица по форме СЗИ-6 (ГОСУСЛУГИ РФ ,МФЦ)
            </td>
          </tr>
          <tr>
            <td>16</td>
            <td>Выписка ЕГИСО (сведения о полученных пособиях)</td>
          </tr>
          <tr>
            <td>17</td>
            <td>Выписка ФНС об открытых счетах</td>
          </tr>
          <tr>
            <td>18</td>
            <td>
              Выписка из Единого государственного реестра недвижимости (ЕГРН) о
              правах отдельного лица на имевшиеся (имеющиеся) у него объекты
              недвижимости за три года на территории РФ
            </td>
          </tr>
          <tr>
            <td>19</td>
            <td>Копия ПТС (при наличии)</td>
          </tr>
          <tr>
            <td>20</td>
            <td>Копия СТС (при наличии)</td>
          </tr>
          <tr>
            <td>21</td>
            <td>Копия трудовой книжки (бумажная, электронная версии)</td>
          </tr>
          <tr>
            <td>22</td>
            <td>Справка о размере пенсии</td>
          </tr>
          <tr>
            <td>23</td>
            <td>Справки о задолженностях</td>
          </tr>
          <tr>
            <td>24</td>
            <td>Выписка ЕГРН</td>
          </tr>
          <tr>
            <td>25</td>
            <td>Свидетельство Индивидуального предпринимателя (при наличии)</td>
          </tr>
          <tr>
            <td>26</td>
            <td>Квитанция о внесении депозита суда на 25 000 руб.</td>
          </tr>
          <tr>
            <td>27</td>
            <td>Квитанции о судебных расходах</td>
          </tr>
          <tr>
            <td>28</td>
            <td>Сведения о задолженностях с сайта ФССП</td>
          </tr>
          <tr>
            <td>29</td>
            <td>Сведения о статусе ИП ЕГРИП</td>
          </tr>
          <tr>
            <td>30</td>
            <td>
              Информация о регистрации несовершеннолетних детей (выписка из
              домовой книги )
            </td>
          </tr>
          <tr>
            <td>31</td>
            <td>Свидетельство о смерти супруга</td>
          </tr>
          <tr>
            <td>32</td>
            <td>Нотариальное соглашение об уплате алиментов</td>
          </tr>
          <tr>
            <td>33</td>
            <td>Копия 2 НДФЛ за предыдущие 3 года по супругу</td>
          </tr>
          <tr>
            <td>34</td>
            <td>
              Выписки ГИБДД из государственного реестра транспортных средств по
              должнику
            </td>
          </tr>
          <tr>
            <td>35</td>
            <td>
              Выписки ГИБДД из государственного реестра транспортных средств по
              должнику
            </td>
          </tr>
          <tr>
            <td>36</td>
            <td>Выписка ЕГРН по супругу</td>
          </tr>
          <tr>
            <td>37</td>
            <td>Временная регистрация по должнику</td>
          </tr>
          <tr>
            <td>38</td>
            <td>Временная регистрация по супругу</td>
          </tr>
          <tr>
            <td>39</td>
            <td>Копия ИНН по супругу</td>
          </tr>
          <tr>
            <td>40</td>
            <td>Выписка ГИМС по должнику</td>
          </tr>
          <tr>
            <td>41</td>
            <td>Выписка ГИМС по супругу</td>
          </tr>
          <tr>
            <td>42</td>
            <td>Выписка Гостехнадзор по должнику</td>
          </tr>
          <tr>
            <td>43</td>
            <td>Выписка Гостехнадзор по супругу</td>
          </tr>
          <tr>
            <td>44</td>
            <td>Судебные акты по делу о банкротстве</td>
          </tr>
          <tr>
            <td>45</td>
            <td>Выписка из реестра лицензии Росгвардии по должнику</td>
          </tr>
          <tr>
            <td>46</td>
            <td>Выписка из реестра лицензии Росгвардии по супругу</td>
          </tr>
          <tr>
            <td>47</td>
            <td>Выписка из технического паспорта БТИ по должнику</td>
          </tr>
          <tr>
            <td>48</td>
            <td>Выписка из технического паспорта БТИ по супругу</td>
          </tr>
          <tr>
            <td>49</td>
            <td>Документ об утилизации транспортного средства</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
