import { useEffect } from "react";
import styles from "./Checkbox.module.scss";
import cn from "classnames";

export const Checkbox = ({ value, setValue, error, setError }) => {
  useEffect(() => {
    if (value === true && setError) {
      setError(false);
    }
  }, [value, setError]);

  return (
    <label className={styles.checkboxWrapper}>
      <input
        type="checkbox"
        checked={value}
        onChange={() => setValue(!value)}
        className={styles.checkbox}
      />
      <span
        className={cn(styles.checkSpan, {
          [styles.error]: error,
        })}
      ></span>
    </label>
  );
};
